import { Stack, Spinner, SpinnerSize } from '@fluentui/react';
import React from "react";

export default class Loading extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Stack style={{ width: '100\u0025', height: '100\u0025' }} verticalAlign="center">
                <Stack.Item align="center">
                    <Spinner size={SpinnerSize.large} />
                </Stack.Item>
            </Stack>
        )
    }
}
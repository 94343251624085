import {
    usePropsFor,
    VideoGallery,
    ControlBar,
    CameraButton,
    MicrophoneButton,
    EndCallButton,
    DevicesButton
} from '@azure/communication-react';
import { Stack } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import IvsPlayer from './IvsPlayer';

function CallingComponents(props) {
    const {
      streamUrl
    } = props;

    const videoGalleryProps = usePropsFor(VideoGallery);
    const cameraProps = usePropsFor(CameraButton);
    const microphoneProps = usePropsFor(MicrophoneButton);
    const devicesProps = usePropsFor(DevicesButton);
    const endCallProps = usePropsFor(EndCallButton);

    videoGalleryProps.layout = "floatingLocalVideo";
    videoGalleryProps.localVideoViewOptions = {
        scalingMode: 'fit-to-frame',
        isMirrored: true
      };
    videoGalleryProps.remoteVideoViewOptions = {
        scalingMode: 'fit-to-frame',
        isMirrored: false
      };


    const [callEnded, setCallEnded] = useState(false);

    const onHangup = useCallback(async () => {
      await endCallProps.onHangUp();
      setCallEnded(true);
    }, [endCallProps]);
    
    if (callEnded) {
      return <IvsPlayer streamUrl={streamUrl} />;
    }
    
    return (
        <Stack>
          <div style={{ height: '100vh' }}>
            {videoGalleryProps && <VideoGallery  remoteVideoTileMenu={false} {...videoGalleryProps} />}
          </div>
    
          <ControlBar layout="floatingBottom">
            {cameraProps && <CameraButton {...cameraProps} />}
            {microphoneProps && <MicrophoneButton {...microphoneProps} />}
            {devicesProps && <DevicesButton {...devicesProps} />}
            {endCallProps && <EndCallButton {...endCallProps} onHangUp={onHangup} />}
          </ControlBar>
        </Stack>
    );
}

function CallEnded() {
    return <h1>You ended the call.</h1>;
  }

export default CallingComponents;
import React, { useEffect, useRef, useState } from 'react';

const IvsPlayer = (props) => {
  const {
    streamUrl
  } = props;

  const { IVSPlayer } = window;
  const { isPlayerSupported } = IVSPlayer;

  const { ENDED, PLAYING, READY } = IVSPlayer.PlayerState;
  const { ERROR } = IVSPlayer.PlayerEventType;

  const [loading, setLoading] = useState(true);

  const player = useRef(null);
  const playerBaseEl = useRef(null);
  const videoEl = useRef(null);

  useEffect(() => {
    if (!isPlayerSupported) {
      console.warn(
        'The current browser does not support the Amazon IVS player.',
      );

      return;
    }

    const onStateChange = () => {
      const playerState = player.current.getState();

      console.log(`Player State - ${playerState}`);
      setLoading(playerState !== PLAYING);
    };

    const onError = (err) => {
      console.warn('Player Event - ERROR:', err);
    };

    player.current = IVSPlayer.create();
    player.current.attachHTMLVideoElement(videoEl.current);
    player.current.load(streamUrl);
    player.current.play();

    player.current.addEventListener(READY, onStateChange);
    player.current.addEventListener(PLAYING, onStateChange);
    player.current.addEventListener(ENDED, onStateChange);
    player.current.addEventListener(ERROR, onError);

    return () => {
      player.current.removeEventListener(READY, onStateChange);
      player.current.removeEventListener(PLAYING, onStateChange);
      player.current.removeEventListener(ENDED, onStateChange);
      player.current.removeEventListener(ERROR, onError);
    };
  }, [IVSPlayer, isPlayerSupported, streamUrl]);

  // cleanup
  useEffect( () => () => {
    console.log("unmount")
    
    // destroy  amazon-ivs
    player.current.delete()
  }, [] );

  return (
    <div className="IvsPlayer" ref={playerBaseEl}>
      <div className="IvsPlayer-videoBox"></div>
        <div className="IvsPlayer-video" style={{ display:"flex", justifyContent:"center", alignItems:"center",height:"100vh", boxSizing:"border-box", padding:"0.5rem" }}>
          <video ref={videoEl} playsInline style={{ width:"100%", height:"100%", backgroundColor: "rgb(243, 242, 241)", borderRadius:"4px" }}></video>
        </div>
    </div>
  );
};

export default IvsPlayer;

import { Call, CallAgent, LocalVideoStream } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { 
  FluentThemeProvider, 
  LocalizationProvider, 
  DEFAULT_COMPONENT_ICONS, 
  COMPONENT_LOCALE_DE_DE,
  CallClientProvider,
  CallAgentProvider,
  CallProvider,
  createStatefulCallClient,
  StatefulCallClient
} from '@azure/communication-react';
import { initializeIcons, registerIcons } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { utils } from "./Utils";

import Loading from './Loading';
import CallingComponents from './CallComponentsStateful';
import IvsPlayer from './IvsPlayer';

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

function App() {
  const [azureIdentity, setAzureIdentity] = useState();
  const [azureCredential, setAzureCredential] = useState();
  const [displayName, setDisplayName] = useState();
  const [meeting, setMeeting] = useState();
  const [streamUrl, setStreamUrl] = useState();

  const [statefulCallClient, setStatefulCallClient] = useState();
  const [callAgent, setCallAgent] = useState();
  const [call, setCall] = useState();

  // run only once on pageload
  useEffect(() => {
    utils.getAuth().then((e) => {
      if(e.azureidentity)                 setAzureIdentity(e.azureidentity)
      if(e.azuretoken)                    setAzureCredential(new AzureCommunicationTokenCredential(e.azuretoken.token))
      if(e.userinfo && e.userinfo.name)   setDisplayName(e.userinfo.name)
      if(e.meeting)                       setMeeting(e.meeting)
      if(e.streamurl)                     setStreamUrl(e.streamurl)
    })
  }, []);
  
  useEffect(() => {
    if(azureIdentity && azureIdentity.communicationUserId) {
      setStatefulCallClient(
        createStatefulCallClient({ userId: azureIdentity })
      );
    }
  }, [azureIdentity]);

  useEffect(() => {
    if (callAgent === undefined && statefulCallClient && azureCredential && displayName) {
      const createCallAgent = async () => {
        setCallAgent(await statefulCallClient.createCallAgent(azureCredential, { displayName: displayName }));
      };
      createCallAgent();
    }
  }, [callAgent, statefulCallClient, azureCredential, displayName]);

  useEffect(() => {
    if (callAgent !== undefined && meeting) {
      setCall(callAgent.join({ meetingLink: meeting, callOptions: getCallOptions(statefulCallClient) }))
    }
  }, [callAgent, meeting]);

  return (
    <FluentThemeProvider>
      <LocalizationProvider locale={COMPONENT_LOCALE_DE_DE}>
        {!callAgent && ( 
          <Loading label="validate token" />
        )}

        {statefulCallClient && ( 
          <CallClientProvider callClient={statefulCallClient}>
            {callAgent && (
              <CallAgentProvider callAgent={callAgent}>
                {call && (
                  <CallProvider call={call}>
                    <CallingComponents streamUrl={streamUrl} />
                  </CallProvider>
                )}

                {!call && (
                  <IvsPlayer streamUrl={streamUrl} />
                )}
              </CallAgentProvider>
            )}
          </CallClientProvider>
        )}
      </LocalizationProvider>
    </FluentThemeProvider>
  );
}

async function getCallOptions(callClient) {
/*  let deviceManager = await callClient.getDeviceManager();

  console.log(deviceManager)

  let videoOpts = undefined

  let permissions = await deviceManager.askDevicePermission({ audio: true, video: true })
  let cameras = await deviceManager.getCameras()
  let cameraDevice = cameras[0]

  if (!cameraDevice || cameraDevice?.id === 'camera:') {
    videoOpts = [new LocalVideoStream(cameraDevice)]
  }
*/

  let callOptions = {
    videoOptions: {
      localVideoStreams: undefined
//        localVideoStreams: videoOpts
    },
    audioOptions: {
        muted: false
    }
  };

  return callOptions;
}

export default App;
